//angular imports
import { Component, Signal, computed, signal } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

//local imports
import { WayFinderTile } from '../_models/wayfinderTiles';
import { UserPermissionsService } from '../_services/user-permissions.service';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
    public tiles = computed<WayFinderTile[]>(() => {
        let result = [];

      if (this.userPermissionsService.canViewCatalog()) {
            let tile = new WayFinderTile();

            tile.name = 'Catalog';
            tile.icon = 'archive';
            tile.url = '/home/catalog/view/home';

            result.push(tile);
        }

      if (this.userPermissionsService.canViewLibrary()) {
            let tile = new WayFinderTile();

            tile.name = 'Library';
            tile.icon = 'file';
            tile.url = '/home/documents/view/home';

            result.push(tile);
        }

        return result;
    });

    constructor(
      public router: Router,
      private userPermissionsService: UserPermissionsService,
      private authenticationService: MsalService,
    ) {
      let currentUser = this.authenticationService.instance.getActiveAccount();
    }
}

