//amgular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs
import { Observable } from 'rxjs';

//local

import { AppConfig } from '../app-config';
import { Right } from '../_interfaces/right';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private applicationConfig: AppConfig
  ) { }

  getRights(): Observable<Right[]> {    
    return this.http.get<Right[]>(`${this.applicationConfig.applicationUri}/v2/user/permissions`);
  }
}